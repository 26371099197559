import React, { useState, useContext, useEffect } from "react";

const AdminPage = () => {
  return (
    <div>
      {" "}
      <h1>Vimeo Video Streaming</h1>
      <iframe
        src="https://player.vimeo.com/video/996317789?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
        width="1920"
        height="1080"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        title="test"
      ></iframe>
    </div>
  );
};

export default AdminPage;
