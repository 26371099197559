import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import "./Header.css";

const Header = () => {
  const { isLoggedIn } = useContext(AuthContext);
  return (
    <header className="header">
      <div className="header-right">
        <div className="logo">
          <a href="/">
            <img src="/images/logo.png" alt="Logo" />
          </a>
        </div>
        <nav className="header-nav">
          <Link to="/" className="nav-item">
            Home
          </Link>
          <Link to="/analyze" className="nav-item">
            Analyze
          </Link>
          <Link to="/discussions" className="nav-item">
            Discussion
          </Link>
        </nav>
      </div>
      <div className="header-btn">
        {isLoggedIn ? (
          <>
            <Link to="/myaccount">My Account</Link>
          </>
        ) : (
          <>
            <Link to="/signup">Sign Up</Link>
            <Link to="/signin">Sign In</Link>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
