// formatText.js
export const formatText = (text) => {
  return text
    .replace(
      /###\s(.*?)(\n|$)/g,
      "<h3 style='text-align: left; font-size: 1em; margin: 0;'>$1</h3>"
    ) // 제목 (### 제목:)
    .replace(
      /##\s(.*?)(\n|$)/g,
      "<h2 style='text-align: left; font-size: 1em;'>$1</h2>"
    ) // 제목 (## 제목:)
    .replace(
      /#\s(.*?)(\n|$)/g,
      "<h1 style='text-align: left; font-size: 1em;'>$1</h1>"
    ) // 제목 (# 제목:)
    .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>") // ** 강조표시 **
    .replace(/- (.*)/g, "<li style='display:inline;'>- $1</li>") // 목록 (- 항목)
    .replace(/\n/g, "<br>"); // 줄바꿈
};
