import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import HomePage from "./pages/HomePage.jsx";
import AnalyzePage from "./pages/AnalyzePage.jsx";
import DiscussionsPage from "./pages/DiscussionsPage.jsx";
import DiscussionPage from "./pages/DiscussionPage.jsx";
import MyAccountPage from "./pages/MyAccountPage.jsx";
import SignInPage from "./pages/SignInPage.jsx";
import SignUpPage from "./pages/SignUpPage.jsx";
import AdminPage from "./pages/AdminPage.jsx";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import AuthHeader from "./components/layout/AuthHeader";
import AuthFooter from "./components/layout/AuthFooter";
import ScrollToTop from "./components/common/ScrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <HeaderWrapper />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/analyze" element={<AnalyzePage />} />
        <Route path="/discussion/:discussionId" element={<DiscussionPage />} />
        <Route path="/discussions" element={<DiscussionsPage />} />
        <Route path="/myaccount" element={<MyAccountPage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/admin" element={<AdminPage />}></Route>
      </Routes>
      <FooterWrapper />
    </Router>
  );
}

function HeaderWrapper() {
  const location = useLocation();
  const isAuthRoute =
    location.pathname === "/signin" || location.pathname === "/signup";
  return isAuthRoute ? <AuthHeader /> : <Header />;
}

function FooterWrapper() {
  const location = useLocation();
  const isAuthRoute =
    location.pathname === "/signin" || location.pathname === "/signup";
  return isAuthRoute ? <AuthFooter /> : <Footer />;
}

export default App;
