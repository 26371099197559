import React from "react";
import "./BarGraph.css";

function BarGraph({ percentages, height }) {
  const [percentage1, percentage2, percentage3] = percentages;

  return (
    <div className="bar-container" style={{ height: `${height}px` }}>
      {percentage1 > 0 && (
        <div
          className="bar-segment segment-1"
          style={{ width: `${percentage1}%` }}
        ></div>
      )}
      {percentage2 > 0 && (
        <div
          className="bar-segment segment-2"
          style={{ width: `${percentage2}%` }}
        ></div>
      )}
      {percentage3 > 0 && (
        <div
          className="bar-segment segment-3"
          style={{ width: `${percentage3}%` }}
        ></div>
      )}
    </div>
  );
}

export default BarGraph;
