import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      fetchUserData();
    }
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get("/api/user_info");
      setUser(response.data.user);
      setIsLoggedIn(true);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setIsLoggedIn(false);
      setUser(null);
    }
  };

  const login = async (token) => {
    localStorage.setItem("token", token);
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    setIsLoggedIn(true);
    await fetchUserData();
  };

  const setAuth = (token) => {
    if (token) {
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      setIsLoggedIn(true);
      fetchUserData();
    } else {
      logout();
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common["Authorization"];
    setIsLoggedIn(false);
    setUser(null);
    window.location.reload();
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, login, logout, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
