import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import { AuthContext } from "../contexts/AuthContext";
import { UtilityContext } from "../contexts/UtilityContext";
import "./HomePage.css";
import BarGraph from "../components/common/BarGraph";

const HomePage = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const { maxLength, formatTimeVerbose } = useContext(UtilityContext);
  const { getHighestProbability } = useContext(UtilityContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [analyzeloading, setAnalyzeloading] = useState(false);
  const [videoURL, setVideoURL] = useState("");
  const [error, setError] = useState(null);
  const [totalLength, setTotalLength] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(1);
    if (isLoggedIn) {
      fetchTotalLength();
    }
  }, [isLoggedIn]);

  const fetchTotalLength = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get("/api/total_video_length", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTotalLength(response.data.totalLength);
    } catch (err) {
      console.error("Failed to fetch total length", err);
    }
  };

  const fetchData = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get("/api/get_results", {
        params: { page, per_page: 4 },
      });
      setData(response.data.results);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (id) => {
    navigate(`/discussion/${id}`);
  };

  const handleChange = (e) => {
    setVideoURL(e.target.value);
  };

  const extractVideoID = (url) => {
    try {
      const urlObj = new URL(url);
      if (urlObj.hostname === "youtu.be") {
        return urlObj.pathname.slice(1);
      }
      return urlObj.searchParams.get("v");
    } catch (e) {
      return null;
    }
  };

  const isValidYouTubeURL = (url) => {
    const videoID = extractVideoID(url);
    return videoID && videoID.length === 11;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const videoID = extractVideoID(videoURL);
    if (!isValidYouTubeURL(videoURL)) {
      setError("Please enter a valid URL.");
      return;
    }
    setAnalyzeloading(true);
    setError(null);
    const cleanURL = `https://www.youtube.com/watch?v=${videoID}`;
    try {
      const token = localStorage.getItem("token");
      console.log("전송하는 URL:", cleanURL);
      const response = await axios.post(
        "/api/analyze_results",
        { videoURL: cleanURL },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchTotalLength();
      navigate(`/discussion/${response.data.result._id}`);
    } catch (err) {
      if (err.response.data.msg === "Already existed") {
        navigate(`/discussion/${err.response.data._id}`);
        return;
      }
      setError(err.response.data.msg);
    } finally {
      setAnalyzeloading(false);
    }
  };
  const goToSignIn = () => {
    navigate("/signin");
  };
  const remainingLength = maxLength - totalLength;
  const placeholderMessage = !isLoggedIn
    ? "You can access this after signing in."
    : remainingLength <= 0
    ? "There is no remaining usage time."
    : "Enter the link to the YouTube video.";

  return (
    <div className={`container ${analyzeloading ? "loading" : ""}`}>
      {analyzeloading && (
        <div className="loader-overlay">
          <div className="loader">
            <p>
              This task may take several minutes to complete. <br></br>You can
              leave the window, and the analysis will continue. <br></br>Once
              the analysis is complete, you can check the results on your My
              Account page or the Discussion page.
            </p>
            <TailSpin color="#00BFFF" height={80} width={80} />
          </div>
        </div>
      )}
      <div className="home">
        <div className="home-hero">
          <div className="container">
            <h1>Is This Video Politically Biased?</h1>
            <p>
              Quickly and easily find out if your YouTube video is politically
              biased.
            </p>
            {!isLoggedIn ? (
              ""
            ) : (
              <>
                <p>
                  Note: The length of a single video cannot exceed{" "}
                  <b>
                    <u>10 minutes.</u>
                  </b>
                </p>
                <p>
                  Your remaining usage time:{" "}
                  <b>
                    <u>{formatTimeVerbose(remainingLength)}</u>
                  </b>{" "}
                  <a href="/myaccount">View More</a>
                </p>
              </>
            )}
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={videoURL}
                onChange={handleChange}
                placeholder={placeholderMessage}
                disabled={!isLoggedIn || remainingLength <= 0}
              />
              {!isLoggedIn ? (
                <button onClick={goToSignIn} disabled={remainingLength <= 0}>
                  Sign In and Analyze
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={!isLoggedIn || remainingLength <= 0}
                >
                  Analyze
                </button>
              )}
            </form>
            {error && <p style={{ color: "red" }}>{error}</p>}
          </div>
        </div>
        <div className="videos">
          <h2>Preview Analysis Results</h2>
          {data.length > 0 ? (
            <div className="video-container">
              {data.map((item) => (
                <div
                  key={item._id}
                  className="video-item"
                  onClick={() => handleClick(item._id)}
                >
                  <img
                    src={item.videoImage}
                    alt={item.videoTitle}
                    className="video-image"
                  />
                  <h3>{item.videoTitle}</h3>
                  <p style={{ margin: 0 }}>{getHighestProbability(item)}</p>
                  <BarGraph
                    percentages={[
                      item.progressiveProb,
                      item.neutralProb,
                      item.conservativeProb,
                    ]}
                    height={10}
                  />
                </div>
              ))}
            </div>
          ) : (
            <p>Loading...</p>
          )}
          {loading && <p>Loading...</p>}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
